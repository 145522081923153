import ContentBlocks from '../components/content-blocks'
import HeaderHero from '../components/header-hero'
import { Helmet } from 'react-helmet'
import Icon from '../components/icon'
import Layout from '../components/layout'
import LinkItem from '../components/link-item'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'
import SEO from '../components/seo'
import { graphql, Link } from 'gatsby'
import React, { Component } from 'react'
import '../components/resource-header.scss'
import '../components/sidebar.scss'
import '../components/search.scss'

class NewsTemplate extends Component {
    constructor(props) {
        super(props)
        this.state = { query: '' }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(event) {
        this.setState({ query: event.target.value })
    }

    handleSubmit(event) {
        event.preventDefault()
        navigate(`/news/?query=${encodeURIComponent(this.state.query)}`).then()
    }

    render() {
        const { title, category, readingTime, tags, image, updatedAt, metaDescription, metaTags, sidebarLinks } = this.props.data.contentfulResource
        const { categories } = this.props.data.categories
        const { slug } = this.props.pageContext
        const shareURL = `/news/${slug}`
        const contentBlocks = this.props.data.contentfulResource.contentBlocks

        return (
            <Layout>
                <Helmet>
                    <script type="application/ld+json">{JSON.stringify([
                        {
                            '@context': 'http://schema.org',
                            '@type': 'NewsArticle',
                            headline: title,
                            image: image && image.image.fluid.src,
                            author: 'Springboard Trust',
                            datePublished: updatedAt,
                            dateModified: updatedAt,
                            mainEntityOfPage: process.env.GATSBY_SPA_URL,
                            publisher: {
                                '@type': 'EducationalOrganization',
                                name: 'Springboard Trust',
                                logo: {
                                    '@type': 'ImageObject',
                                    url: `${process.env.GATSBY_SPA_URL}/springboard-trust-logo.png`,
                                },
                            },
                        },
                    ])}</script>
                </Helmet>
                <SEO
                    title={title}
                    description={metaDescription && metaDescription.metaDescription ? metaDescription.metaDescription : null}
                    imageUrl={image && image.image.fluid.src.replace(/^\/\//, 'https://')}
                    tags={metaTags && metaTags.metaTags}
                />
                <HeaderHero title={title} tags={tags} category={category} updatedAt={updatedAt} readingTime={readingTime} path={shareURL} image={image}/>
                <div className="content-with-sidebar">
                    <div className="content-with-sidebar-main">
                        <ContentBlocks data={contentBlocks} hasSideBar={true} />
                    </div>
                    <div className="content-with-sidebar-sidebar sidebar hidden-mobile">
                        <div className="search">
                            <form noValidate role="search" className="search-form" onSubmit={this.handleSubmit}>
                                <input
                                    className="search-input"
                                    type="search"
                                    placeholder="Search here..."
                                    autoComplete="off"
                                    autoCorrect="off"
                                    autoCapitalize="off"
                                    spellCheck="false"
                                    required
                                    maxLength="512"
                                    value={this.state.query}
                                    onChange={this.handleChange}
                                />
                                <button className="search-form-submit" type="submit" title="Submit your search query.">
                                    <Icon name="search" />
                                </button>
                                <button className="search-form-reset" type="reset" title="Clear the search query." hidden>
                                    <Icon name="close" />
                                </button>
                            </form>
                        </div>
                        <div className="sidebar-section">
                            <h3>Categories</h3>
                            <ul className="sidebar-list">
                                {
                                    categories.map((categoryName, key) => {
                                        return <li className="sidebar-list-item" key={key}>
                                            <Link className="sidebar-list-item-link" to={`/news/?menu[category]=${categoryName}`}>{categoryName}</Link>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                        {
                            sidebarLinks &&
                            <div className="sidebar-links">
                                <div className="content-set-block">
                                    {
                                        sidebarLinks.map((linkData, key) => {
                                            return <LinkItem type={linkData.internal.type} title={linkData.title} slug={linkData.slug} key={key}/>
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Layout>
        )
    }
}

export default NewsTemplate

NewsTemplate.propTypes = {
    data: PropTypes.shape({
        contentfulResource: PropTypes.shape({
            title: PropTypes.string,
            metaDescription: PropTypes.shape({
                metaDescription: PropTypes.string,
            }),
            metaTags: PropTypes.shape({
                metaTags: PropTypes.string,
            }),
            createdAt: PropTypes.string,
            updatedAt: PropTypes.string,
            category: PropTypes.string,
            readingTime: PropTypes.number,
            tags: PropTypes.array,
            contentBlocks: PropTypes.array,
            sidebarLinks: PropTypes.array,
            image: PropTypes.shape({
                image: PropTypes.shape({
                    fluid: PropTypes.shape(({
                        aspectRatio: PropTypes.number,
                        base64: PropTypes.string,
                        sizes: PropTypes.string,
                        src: PropTypes.string,
                        srcSet: PropTypes.string,
                    })),
                }),
            }),
        }),
        categories: PropTypes.object,
    }),
    path: PropTypes.string,
    pageContext: PropTypes.shape({
        slug: PropTypes.string,
    }),
}

export const query = graphql`
    query ResourceBySlug($slug: String!) {
        categories: allContentfulResource {
            categories: distinct(field: category)
        }
        contentfulResource(slug: {eq: $slug}) {
            title
            createdAt
            updatedAt
            slug
            image {
                image {
                    fluid(maxWidth: 960) {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                    }
                }
                altText
                caption {
                    caption
                }
            }
            readingTime
            tags
            category
            contentBlocks {
                ... on ContentfulImage {
                    id
                    title
                    altText
                    small
                    caption {
                        caption
                    }
                    image {
                        fluid(maxWidth: 920) {
                            base64
                            aspectRatio
                            src
                            srcSet
                            sizes
                        }
                    }
                    internal {
                        type
                    }
                }
                ...ContentfulRichTextContentBlockFragment
                ... on ContentfulContentSetContentBlock {
                    contentful_id
                    internal {
                        type
                    }
                    richTitle {
                        json
                    }
                    title
                    template
                    linkedContent {
                        ... on ContentfulPage {
                            id
                            slug
                            title
                            internal {
                                type
                            }
                            image {
                                image {
                                    fluid(maxWidth: 960) {
                                        aspectRatio
                                        base64
                                        sizes
                                        src
                                        srcSet
                                    }
                                }
                            }
                            contentBlocks {
                                ...ContentfulRichTextContentBlockFragment
                            }
                        }
                        ... on ContentfulResource {
                            id
                            slug
                            title
                            image {
                                image {
                                    fluid(maxWidth: 960) {
                                        aspectRatio
                                        base64
                                        sizes
                                        src
                                        srcSet
                                    }
                                }
                            }
                            category
                            tags
                            readingTime
                            internal {
                                type
                            }
                            contentBlocks {
                                ...ContentfulRichTextContentBlockFragment
                            }
                        }
                    }
                }
                ... on ContentfulTeamContentBlock {
                    id
                    title
                    internal {
                        type
                    }
                    people {
                        id
                        name
                        position
                        email
                        photo {
                            fluid(maxWidth: 500) {
                                base64
                                aspectRatio
                                src
                                sizes
                                srcSet
                            }
                        }
                        phone
                        about {
                            json
                        }
                    }
                }
                ... on ContentfulQuoteContentBlock {
                    id
                    internal {
                        type
                    }
                    author
                    quote {
                        quote
                    }
                }
                ... on ContentfulDownloadSingleFileContentBlock {
                    id
                    title
                    fileTitle
                    fileDescription {
                        fileDescription
                    }
                    internal {
                        type
                    }
                    file {
                        file {
                            url
                        }
                    }
                }
                ... on ContentfulEmbedVideoBlock {
                    id
                    title
                    url
                    small
                    internal {
                      type
                    }
                }
            }
            metaDescription {
                metaDescription
            }
            metaTags {
                metaTags
            }
            sidebarLinks {
                ... on ContentfulPage {
                    id
                    title
                    internal {
                        type
                    }
                    slug
                }
                ... on ContentfulResource {
                    id
                    internal {
                        type
                    }
                    title
                    slug
                }
            }
        }
    }
`
